import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/cover.module.scss';
import Logo from '../svg/logo';
import { getUserData } from '../utils/userData';
import Container from './container';
import Link from './link';

const Cover = ({ desktopCoverImage, mobileCoverImage }) => (
  <section className={styles.cover}>
    <Img
      fluid={desktopCoverImage.fluid}
      className={`${styles.cover__background} ${
        styles.cover__backgroundDesktop
      }`}
      style={{ position: '' }}
      imgStyle={{ objectPosition: '', objectFit: '' }}
      Tag="figure"
      alt="Your Czech beer online guide"
      critical={true}
    />
    <Img
      fluid={mobileCoverImage.fluid}
      className={`${styles.cover__background} ${
        styles.cover__backgroundMobile
      }`}
      style={{ position: '' }}
      imgStyle={{ objectPosition: '', objectFit: '' }}
      Tag="figure"
      alt="Your Czech beer online guide"
      critical={true}
    />
    <Container>
      <div className={styles.cover__logo}>
        <Logo />
      </div>
      <header className={styles.cover__header}>
        <h1 className={styles.cover__title}>
          Your online guide to <br/> Czech beer brands
        </h1>
      </header>

      <footer className={styles.cover__footer}>
        <Link
          to={getUserData() ? '/lessons' : '/register'}
          className={`${styles.cover__footer__button} ${
            styles.cover__footer__buttonRegister
          }`}
        >
          {getUserData() ? 'go to homepage' : 'newcomer? sign up!'}
        </Link>
        <Link
          to={getUserData() ? '/log-out' : '/login'}
          className={`${styles.cover__footer__button} ${
            styles.cover__footer__buttonLogin
          }`}
        >
          {getUserData() ? 'log off' : 'already a member? log in'}
        </Link>
      </footer>
    </Container>
  </section>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        desktopCoverImage: imageSharp(
          original: { src: { regex: "/cover-desktop/" } }
        ) {
          fluid(maxWidth: 2560, sizes: "(min-aspect-ratio: 16/9) 100vw, calc(100vh / 9 * 16)", quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }

        mobileCoverImage: imageSharp(
          original: { src: { regex: "/cover-mobile/" } }
        ) {
          fluid(maxWidth: 990) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `}
    render={data => <Cover {...data} {...props} />}
  />
)
